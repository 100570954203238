import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import App from './App';
import { initializeAnalytics } from './lib/analytics';
import { initSupabase } from './lib/supabase/config';
import { logger } from './lib/utils/logger';
import NoSupabaseConnection from './components/auth/NoSupabaseConnection';

// Import styles
import './index.css';
import './styles/branding.css';
import './styles/prose.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
      retry: 1
    },
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

// Initialize application services
const initializeApp = async () => {
  try {
    logger.info('Initializing application services...');
    
    // Initialize analytics
    initializeAnalytics();
    
    // Initialize Supabase with retries
    const supabaseInitialized = await initSupabase();
    
    if (!supabaseInitialized) {
      root.render(
        <React.StrictMode>
          <NoSupabaseConnection />
        </React.StrictMode>
      );
      return;
    }
    
    logger.info('Application services initialized successfully');

    // Render the app only after successful initialization
    root.render(
      <React.StrictMode>
        <HelmetProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Toaster position="top-right" />
              <App />
            </QueryClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </React.StrictMode>
    );

  } catch (error) {
    logger.error('Failed to initialize application services:', error);
    root.render(
      <React.StrictMode>
        <NoSupabaseConnection />
      </React.StrictMode>
    );
  }
};

// Initialize app
initializeApp();