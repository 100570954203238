import { supabase } from '../supabase/config';
import { logInfo, logError } from '../utils/logger';
import { emailService } from '../email/service';
import type { LoginForm, RegisterForm } from './validation';
import type { AuthError } from '@supabase/supabase-js';
import { RateLimitError } from './errors';

export const authService = {
  login: async (data: LoginForm) => {
    try {
      logInfo('Attempting login', { email: data.email });

      const { data: authData, error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        logError(error, { context: 'Login' });
        throw new Error('Credenciales inválidas');
      }

      logInfo('Login successful', { email: data.email });
      return authData;
    } catch (error) {
      logError(error as Error, { context: 'Login' });
      throw error;
    }
  },

  register: async (data: RegisterForm) => {
    try {
      logInfo('Attempting registration', { email: data.email });

      // Register user with Supabase
      const { data: authData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          data: {
            name: data.name
          },
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        logError(error, { context: 'Registration' });
        if (error.message.includes('already registered')) {
          throw new Error('Este email ya está registrado');
        }
        if (error.message.includes('rate limit')) {
          throw new RateLimitError('Has excedido el límite de intentos. Por favor, espera unos minutos.');
        }
        throw error;
      }

      logInfo('Registration successful', { email: data.email });
      return {
        user: authData.user,
        session: authData.session,
        confirmEmail: true
      };
    } catch (error) {
      logError(error as Error, { context: 'Registration' });
      throw error;
    }
  },

  resendConfirmationEmail: async (email: string) => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        if (error.message.includes('rate limit')) {
          throw new RateLimitError('Has excedido el límite de intentos. Por favor, espera unos minutos.');
        }
        throw error;
      }

      return { error: null };
    } catch (error) {
      logError(error as Error, { context: 'Resend Confirmation Email' });
      return { error };
    }
  },

  logout: async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      logInfo('Logout successful');
    } catch (error) {
      logError(error as Error, { context: 'Logout' });
      throw error;
    }
  }
};