import axios from 'axios';
import { logError } from '../lib/utils/logger';

const api = axios.create({
  baseURL: import.meta.env.VITE_MAKE_API_URL,
  headers: {
    'Authorization': `Token ${import.meta.env.VITE_MAKE_API_TOKEN}`,
    'Content-Type': 'application/json'
  }
});

export const makeApi = {
  async getScenarios() {
    try {
      const response = await api.get('/scenarios');
      return response.data;
    } catch (error) {
      logError(error as Error, { context: 'Make API - Get Scenarios' });
      throw error;
    }
  },

  async executeScenario(scenarioId: string, data: any) {
    try {
      const response = await api.post(`/scenarios/${scenarioId}/run`, data);
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'Make API - Execute Scenario', 
        scenarioId,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  },

  async getExecutionHistory(scenarioId: string) {
    try {
      const response = await api.get(`/scenarios/${scenarioId}/executions`);
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'Make API - Get Execution History',
        scenarioId 
      });
      throw error;
    }
  },

  async getExecutionDetails(scenarioId: string, executionId: string) {
    try {
      const response = await api.get(`/scenarios/${scenarioId}/executions/${executionId}`);
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'Make API - Get Execution Details',
        scenarioId,
        executionId
      });
      throw error;
    }
  }
};