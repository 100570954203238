import axios from 'axios';
import { logError } from '../lib/utils/logger';

const api = axios.create({
  baseURL: import.meta.env.VITE_N8N_API_URL,
  headers: {
    'X-N8N-API-KEY': import.meta.env.VITE_N8N_API_TOKEN
  }
});

export const n8nApi = {
  async executeWorkflow(workflowId: string, data: any) {
    try {
      const response = await api.post(`/api/v1/workflows/${workflowId}/execute`, data);
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'n8n API', 
        workflowId,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  },

  async getExecutionStatus(executionId: string) {
    try {
      const response = await api.get(`/api/v1/executions/${executionId}`);
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'n8n API', 
        executionId,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  },

  async getWorkflows() {
    try {
      const response = await api.get('/api/v1/workflows');
      return response.data;
    } catch (error) {
      logError(error as Error, { 
        context: 'n8n API - Get Workflows',
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      throw error;
    }
  }
};