import { initializeGA, trackGAEvent, trackGAPageView } from './google';
import { initializeFacebookPixel, trackFacebookEvent } from './facebook';
import { logInfo } from '../utils/logger';

export const initializeAnalytics = () => {
  const GA_ENABLED = !!import.meta.env.VITE_GA_TRACKING_ID;
  const FB_ENABLED = !!import.meta.env.VITE_FB_PIXEL_ID;

  if (GA_ENABLED) {
    initializeGA();
  }

  if (FB_ENABLED) {
    initializeFacebookPixel();
  }

  logInfo('Analytics services initialized', { GA_ENABLED, FB_ENABLED });
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  const event = { category, action, label, value };
  
  if (import.meta.env.VITE_GA_TRACKING_ID) {
    trackGAEvent(event);
  }
  
  if (import.meta.env.VITE_FB_PIXEL_ID) {
    trackFacebookEvent(action, { category, label, value });
  }
};

export const trackPageView = (path: string) => {
  if (import.meta.env.VITE_GA_TRACKING_ID) {
    trackGAPageView(path);
  }
  
  if (import.meta.env.VITE_FB_PIXEL_ID) {
    trackFacebookEvent('PageView');
  }
};