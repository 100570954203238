import { useEffect, useState } from 'react';
import { supabase } from '../../supabase/config';
import { logInfo, logError } from '../../utils/logger';
import type { User, AuthError } from '@supabase/supabase-js';
import type { Profile } from '../../supabase/types';

export interface AuthUser extends User {
  profile?: Profile;
}

export const useAuth = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get initial session and user profile
    const initializeAuth = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;
        
        if (session?.user) {
          // Get user profile
          const { data: profile } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.user.id)
            .single();

          setUser({ ...session.user, profile });
        }
      } catch (error) {
        logError(error as Error, { context: 'Auth Initialization' });
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      logInfo('Auth state changed', { event });

      if (session?.user) {
        // Get user profile on auth change
        const { data: profile } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        setUser({ ...session.user, profile });
      } else {
        setUser(null);
      }
      
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleAuthError = (error: AuthError) => {
    switch (error.message) {
      case 'Invalid login credentials':
        throw new Error('Credenciales inválidas');
      case 'Email not confirmed':
        throw new Error('Por favor, confirma tu email antes de iniciar sesión');
      case 'Password recovery required':
        throw new Error('Necesitas restablecer tu contraseña');
      case 'Rate limit exceeded':
        throw new Error('Demasiados intentos. Por favor, espera unos minutos');
      default:
        logError(error, { context: 'Auth Error Handler' });
        throw new Error('Error en la autenticación');
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`
        }
      });

      if (error) {
        handleAuthError(error);
      }

      return data;
    } catch (error) {
      logError(error as Error, { context: 'Auth Login' });
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth/reset-password`
      });

      if (error) throw error;
      
      logInfo('Password reset email sent', { email });
    } catch (error) {
      logError(error as Error, { context: 'Password Reset' });
      throw error;
    }
  };

  const updatePassword = async (password: string) => {
    try {
      const { error } = await supabase.auth.updateUser({ password });
      
      if (error) throw error;
      
      logInfo('Password updated successfully');
    } catch (error) {
      logError(error as Error, { context: 'Password Update' });
      throw error;
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      logInfo('User logged out successfully');
    } catch (error) {
      logError(error as Error, { context: 'Auth Logout' });
      throw error;
    }
  };

  return {
    user,
    loading,
    login,
    logout,
    resetPassword,
    updatePassword
  };
};