import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, RefreshCcw } from 'lucide-react';
import AuthWrapper from './AuthWrapper';

interface EmailConfirmationProps {
  email: string;
  onResendEmail: () => Promise<void>;
}

const EmailConfirmation: React.FC<EmailConfirmationProps> = ({ email, onResendEmail }) => {
  return (
    <AuthWrapper>
      <div className="text-center">
        <div className="bg-primary/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
          <Mail className="h-8 w-8 text-primary" />
        </div>
        
        <h2 className="text-2xl font-bold text-white mb-4">
          Confirma tu Email
        </h2>
        
        <p className="text-gray-400 mb-6">
          Te hemos enviado un email de confirmación a <strong className="text-white">{email}</strong>. 
          Por favor, revisa tu bandeja de entrada y sigue las instrucciones para activar tu cuenta.
        </p>

        <div className="space-y-4">
          <button
            onClick={onResendEmail}
            className="flex items-center justify-center w-full px-4 py-2 bg-dark text-white rounded-md hover:bg-dark-light transition-colors"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Reenviar Email
          </button>

          <p className="text-gray-400">
            ¿Ya confirmaste tu email?{' '}
            <Link to="/login" className="text-primary hover:text-primary-light">
              Inicia sesión aquí
            </Link>
          </p>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default EmailConfirmation;