import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '../../lib/supabase/config';
import { logInfo, logError } from '../../lib/utils/logger';
import { AlertCircle } from 'lucide-react';

const AuthCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get session from URL
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          throw sessionError;
        }

        if (session) {
          logInfo('User authenticated successfully', { userId: session.user.id });
          
          // Get redirect destination from URL params or default to dashboard
          const redirectTo = searchParams.get('redirectTo') || '/dashboard';
          navigate(redirectTo, { replace: true });
        } else {
          throw new Error('No se encontró sesión de usuario');
        }
      } catch (error) {
        logError(error as Error, { context: 'Auth Callback' });
        let errorMessage = 'Error de autenticación';
        
        if (error instanceof Error) {
          // Mensajes de error más descriptivos
          switch (error.message) {
            case 'Invalid login credentials':
              errorMessage = 'Credenciales inválidas';
              break;
            case 'Email not confirmed':
              errorMessage = 'Por favor, confirma tu email antes de iniciar sesión';
              break;
            case 'Invalid JWT':
              errorMessage = 'Sesión inválida o expirada';
              break;
            default:
              errorMessage = error.message;
          }
        }
        
        setError(errorMessage);
        // No redirigir inmediatamente para mostrar el error
        setTimeout(() => navigate('/login', { replace: true }), 3000);
      }
    };

    handleCallback();
  }, [navigate, searchParams]);

  if (error) {
    return (
      <div className="min-h-screen bg-dark flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-dark-lighter p-6 rounded-lg border border-red-500/50">
          <div className="flex items-center space-x-3 text-red-500">
            <AlertCircle className="h-5 w-5" />
            <p>{error}</p>
          </div>
          <p className="mt-4 text-gray-400 text-sm">
            Redirigiendo al inicio de sesión...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-dark flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
};

export default AuthCallback;