import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Play, Pause, Settings, Plus, Activity } from 'lucide-react';
import { supabase } from '../../lib/supabase/config';
import { automationService } from '../../services/automationService';
import { toast } from 'react-hot-toast';

const AutomationsDashboard = () => {
  const { data: automations, isLoading } = useQuery({
    queryKey: ['user-automations'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('user_automations')
        .select(`
          *,
          product:automation_products(*)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    }
  });

  const handleExecute = async (automationId: string) => {
    try {
      await automationService.executeAutomation(automationId);
      toast.success('Automatización ejecutada correctamente');
    } catch (error) {
      toast.error('Error al ejecutar la automatización');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-white">Mis Automatizaciones</h1>
          <p className="text-gray-400 mt-1">
            Gestiona y monitorea tus automatizaciones
          </p>
        </div>
        
        <button className="btn-primary">
          <Plus className="w-4 h-4 mr-2" />
          Nueva Automatización
        </button>
      </div>

      <div className="grid gap-6">
        {automations?.map((automation) => (
          <div key={automation.id} className="card">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-semibold text-white">
                  {automation.product.name}
                </h3>
                <p className="text-gray-400 mt-1">
                  {automation.product.description}
                </p>
              </div>
              
              <div className="flex items-center space-x-2">
                <button 
                  onClick={() => handleExecute(automation.id)}
                  className="p-2 text-gray-400 hover:text-white rounded-lg hover:bg-dark transition-colors"
                >
                  <Play className="h-5 w-5" />
                </button>
                <button className="p-2 text-gray-400 hover:text-white rounded-lg hover:bg-dark transition-colors">
                  <Pause className="h-5 w-5" />
                </button>
                <button className="p-2 text-gray-400 hover:text-white rounded-lg hover:bg-dark transition-colors">
                  <Settings className="h-5 w-5" />
                </button>
                <button className="p-2 text-gray-400 hover:text-white rounded-lg hover:bg-dark transition-colors">
                  <Activity className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="mt-4 pt-4 border-t border-gray-800">
              <div className="flex items-center justify-between text-sm text-gray-400">
                <div>
                  <span className="px-2 py-1 bg-dark rounded text-primary text-xs">
                    {automation.product.platform}
                  </span>
                </div>
                <span>
                  Última ejecución: {new Date(automation.updated_at).toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutomationsDashboard;