import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Zap, Activity, Key } from 'lucide-react';
import { supabase } from '../../lib/supabase/config';

const DashboardHome = () => {
  const { data: stats, isLoading } = useQuery({
    queryKey: ['dashboard-stats'],
    queryFn: async () => {
      const [automations, apiKeys, logs] = await Promise.all([
        supabase.from('user_automations').select('*', { count: 'exact' }),
        supabase.from('api_keys').select('*', { count: 'exact' }),
        supabase.from('automation_logs').select('*', { count: 'exact' })
      ]);

      return {
        automations: automations.count || 0,
        apiKeys: apiKeys.count || 0,
        logs: logs.count || 0
      };
    }
  });

  const cards = [
    {
      title: 'Automatizaciones Activas',
      value: stats?.automations || 0,
      icon: Zap,
      color: 'text-primary'
    },
    {
      title: 'API Keys',
      value: stats?.apiKeys || 0,
      icon: Key,
      color: 'text-green-500'
    },
    {
      title: 'Ejecuciones Totales',
      value: stats?.logs || 0,
      icon: Activity,
      color: 'text-blue-500'
    }
  ];

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl font-bold text-white">Dashboard</h1>
        <p className="text-gray-400 mt-1">
          Bienvenido a tu panel de control
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <div key={index} className="bg-dark-lighter p-6 rounded-xl border border-gray-800">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-400 text-sm">{card.title}</p>
                <h3 className="text-2xl font-semibold text-white mt-1">
                  {card.value}
                </h3>
              </div>
              <div className="bg-dark p-3 rounded-lg">
                <card.icon className={`w-6 h-6 ${card.color}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardHome;