import React from 'react';
import { AlertCircle, RefreshCcw } from 'lucide-react';
import { initSupabase } from '../../lib/supabase/config';

const NoSupabaseConnection = () => {
  const handleRetry = async () => {
    const success = await initSupabase();
    if (success) {
      window.location.reload();
    }
  };

  return (
    <div className="min-h-screen bg-dark flex items-center justify-center px-4">
      <div className="max-w-md w-full">
        <div className="bg-dark-lighter p-8 rounded-lg shadow-lg border border-gray-800 text-center">
          <AlertCircle className="h-12 w-12 text-primary mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-white mb-4">
            Error de Conexión
          </h2>
          <p className="text-gray-400 mb-6">
            No se pudo establecer conexión con Supabase. Por favor, asegúrate de que:
          </p>
          <ul className="text-left text-gray-400 mb-6 space-y-2">
            <li className="flex items-start">
              <span className="mr-2">•</span>
              Has conectado tu proyecto a Supabase (botón "Connect to Supabase" arriba)
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              Las credenciales de Supabase son correctas
            </li>
            <li className="flex items-start">
              <span className="mr-2">•</span>
              Tu conexión a internet es estable
            </li>
          </ul>
          <button
            onClick={handleRetry}
            className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-light transition-colors"
          >
            <RefreshCcw className="w-4 h-4 mr-2" />
            Reintentar Conexión
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoSupabaseConnection;