import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LayoutDashboard, Zap, Key, Settings, LogOut } from 'lucide-react';
import { useAuth } from '../../lib/auth/hooks/useAuth';
import Logo from '../Logo';

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const { user, logout } = useAuth();

  const menuItems = [
    { 
      icon: LayoutDashboard, 
      name: 'Dashboard', 
      path: '/dashboard' 
    },
    { 
      icon: Zap, 
      name: 'Automatizaciones', 
      path: '/dashboard/automations' 
    },
    { 
      icon: Key, 
      name: 'API Keys', 
      path: '/dashboard/api-keys' 
    },
    { 
      icon: Settings, 
      name: 'Configuración', 
      path: '/dashboard/settings' 
    }
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="min-h-screen bg-dark flex">
      {/* Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-dark-lighter border-r border-gray-800">
        <div className="flex flex-col h-full">
          <div className="p-4">
            <Link to="/" className="block mb-6">
              <Logo />
            </Link>
            <div className="flex items-center space-x-3 mb-6">
              <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center">
                <span className="text-white font-semibold">
                  {user?.name?.charAt(0)}
                </span>
              </div>
              <div>
                <h2 className="text-white font-medium">{user?.name}</h2>
                <p className="text-sm text-gray-400">{user?.email}</p>
              </div>
            </div>
          </div>
          
          <nav className="flex-1 p-4">
            <ul className="space-y-2">
              {menuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors ${
                      isActive(item.path)
                        ? 'bg-primary text-white'
                        : 'text-gray-300 hover:text-white hover:bg-dark'
                    }`}
                  >
                    <item.icon className="w-5 h-5" />
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <div className="p-4 border-t border-gray-800">
            <button
              onClick={() => logout()}
              className="flex items-center space-x-3 px-4 py-2 text-gray-300 hover:text-white hover:bg-dark rounded-lg transition-colors w-full"
            >
              <LogOut className="w-5 h-5" />
              <span>Cerrar Sesión</span>
            </button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="ml-64 w-full">
        <div className="p-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;