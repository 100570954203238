import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Key, Plus, Trash2, Copy, CheckCircle, AlertCircle, Loader2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { supabase } from '../../lib/supabase/config';
import { trackEvent } from '../../lib/analytics';

interface ApiKey {
  id: string;
  name: string;
  key: string;
  created_at: string;
  last_used_at: string | null;
  expires_at: string | null;
}

const ApiKeys = () => {
  const queryClient = useQueryClient();
  const [isCreating, setIsCreating] = useState(false);
  const [newKeyName, setNewKeyName] = useState('');
  const [copiedKey, setCopiedKey] = useState<string | null>(null);

  // Fetch API keys
  const { data: apiKeys, isLoading } = useQuery({
    queryKey: ['api-keys'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('api_keys')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as ApiKey[];
    }
  });

  // Create API key mutation
  const createKey = useMutation({
    mutationFn: async (name: string) => {
      const { data, error } = await supabase
        .from('api_keys')
        .insert({
          name,
          key: crypto.randomUUID(),
          expires_at: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['api-keys'] });
      setIsCreating(false);
      setNewKeyName('');
      toast.success('API key creada correctamente');
      trackEvent('API Keys', 'Create', 'Success');
    },
    onError: (error) => {
      console.error('Error creating API key:', error);
      toast.error('Error al crear la API key');
      trackEvent('API Keys', 'Create', 'Error');
    }
  });

  // Delete API key mutation
  const deleteKey = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase
        .from('api_keys')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['api-keys'] });
      toast.success('API key eliminada correctamente');
      trackEvent('API Keys', 'Delete', 'Success');
    },
    onError: (error) => {
      console.error('Error deleting API key:', error);
      toast.error('Error al eliminar la API key');
      trackEvent('API Keys', 'Delete', 'Error');
    }
  });

  const handleCopyKey = async (key: string) => {
    try {
      await navigator.clipboard.writeText(key);
      setCopiedKey(key);
      setTimeout(() => setCopiedKey(null), 2000);
      toast.success('API key copiada al portapapeles');
      trackEvent('API Keys', 'Copy', 'Success');
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      toast.error('Error al copiar la API key');
      trackEvent('API Keys', 'Copy', 'Error');
    }
  };

  const handleCreateKey = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newKeyName.trim()) {
      toast.error('El nombre es requerido');
      return;
    }
    await createKey.mutateAsync(newKeyName);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-white">API Keys</h1>
          <p className="text-gray-400 mt-1">
            Gestiona tus claves de API para las automatizaciones
          </p>
        </div>
        
        <button
          onClick={() => setIsCreating(true)}
          className="flex items-center px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-light transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Nueva API Key
        </button>
      </div>

      {isCreating && (
        <div className="bg-dark-lighter rounded-lg border border-gray-800 p-6">
          <h3 className="text-lg font-medium text-white mb-4">
            Crear Nueva API Key
          </h3>
          <form onSubmit={handleCreateKey} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
                Nombre
              </label>
              <input
                type="text"
                id="name"
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
                className="w-full px-4 py-2 bg-dark border border-gray-700 rounded-md focus:ring-primary focus:border-primary text-white"
                placeholder="Ej: Automatización de Leads"
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setIsCreating(false)}
                className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={createKey.isPending}
                className="flex items-center px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-light transition-colors disabled:opacity-50"
              >
                {createKey.isPending ? (
                  <>
                    <Loader2 className="animate-spin h-4 w-4 mr-2" />
                    Creando...
                  </>
                ) : (
                  'Crear API Key'
                )}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="space-y-4">
        {apiKeys?.length === 0 ? (
          <div className="bg-dark-lighter p-8 rounded-lg border border-gray-800 text-center">
            <Key className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-xl font-semibold text-white mb-2">No hay API keys</h3>
            <p className="text-gray-400">
              Crea tu primera API key para empezar a usar las automatizaciones.
            </p>
          </div>
        ) : (
          apiKeys?.map((key) => (
            <div key={key.id} className="bg-dark-lighter p-6 rounded-lg border border-gray-800">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-lg font-semibold text-white">{key.name}</h3>
                  <div className="flex items-center mt-2">
                    <code className="px-3 py-1 bg-dark rounded text-sm text-gray-300 font-mono">
                      {key.key}
                    </code>
                    <button
                      onClick={() => handleCopyKey(key.key)}
                      className="ml-2 p-1 text-gray-400 hover:text-white rounded transition-colors"
                      title="Copiar API key"
                    >
                      {copiedKey === key.key ? (
                        <CheckCircle className="h-5 w-5 text-green-500" />
                      ) : (
                        <Copy className="h-5 w-5" />
                      )}
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => {
                    if (window.confirm('¿Estás seguro de eliminar esta API key?')) {
                      deleteKey.mutate(key.id);
                    }
                  }}
                  className="p-2 text-gray-400 hover:text-red-500 rounded-lg hover:bg-dark transition-colors"
                  title="Eliminar API key"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
              <div className="mt-4 pt-4 border-t border-gray-800">
                <div className="flex items-center justify-between text-sm text-gray-400">
                  <div className="flex items-center space-x-2">
                    <span>Creada: {new Date(key.created_at).toLocaleDateString()}</span>
                    {key.last_used_at && (
                      <>
                        <span>•</span>
                        <span>Último uso: {new Date(key.last_used_at).toLocaleDateString()}</span>
                      </>
                    )}
                  </div>
                  {key.expires_at && (
                    <div className="flex items-center space-x-1">
                      <AlertCircle className="h-4 w-4" />
                      <span>Expira: {new Date(key.expires_at).toLocaleDateString()}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ApiKeys;