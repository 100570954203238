import React from 'react';
import { CircleDot } from 'lucide-react';
import { BRANDING } from '../config/branding';

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <CircleDot className="h-8 w-8 text-primary" />
      <div className="ml-2">
        <span className="text-xl font-bold text-white">{BRANDING.logo.text}</span>
        <div className="text-xs text-gray-400 -mt-1">{BRANDING.logo.subtext}</div>
      </div>
    </div>
  );
};

export default Logo;