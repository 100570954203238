import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-dark flex flex-col">
      <header className="p-4 border-b border-gray-800">
        <div className="max-w-7xl mx-auto">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </header>

      <main className="flex-1 flex items-center justify-center p-4">
        <div className="max-w-md w-full">
          <div className="bg-dark-lighter p-8 rounded-lg shadow-lg border border-gray-800">
            {children}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AuthWrapper;