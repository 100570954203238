export const BRANDING = {
  colors: {
    primary: {
      DEFAULT: '#DC0000',
      light: '#FF1A1A', 
      dark: '#B30000'
    },
    dark: {
      DEFAULT: '#121212',
      lighter: '#1E1E1E',
      light: '#282828'
    }
  },
  fonts: {
    heading: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
  },
  logo: {
    text: 'palomares',
    subtext: 'DIGITAL COACH'
  }
} as const;