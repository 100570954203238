import { supabase } from '../lib/supabase/config';
import { makeApi } from './makeApi';
import { n8nApi } from './n8nApi';
import { logError } from '../lib/utils/logger';

export const automationService = {
  async executeAutomation(automationId: string) {
    try {
      // Get automation details
      const { data: automation, error } = await supabase
        .from('user_automations')
        .select(`
          *,
          product:automation_products(*)
        `)
        .eq('id', automationId)
        .single();

      if (error) throw error;

      // Execute based on platform
      let result;
      if (automation.product.platform === 'make') {
        result = await makeApi.executeFlow(automation.product.flow_id, automation.settings);
      } else {
        result = await n8nApi.executeWorkflow(automation.product.flow_id, automation.settings);
      }

      // Log execution
      await supabase.from('automation_logs').insert({
        automation_id: automationId,
        status: 'success',
        result
      });

      return result;
    } catch (error) {
      logError(error as Error, { context: 'Execute Automation', automationId });
      
      // Log error
      await supabase.from('automation_logs').insert({
        automation_id: automationId,
        status: 'error',
        error: error instanceof Error ? error.message : 'Unknown error'
      });

      throw error;
    }
  },

  async getAutomationLogs(automationId: string) {
    const { data, error } = await supabase
      .from('automation_logs')
      .select('*')
      .eq('automation_id', automationId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  },

  async generateApiKey(name: string) {
    const { data, error } = await supabase
      .from('api_keys')
      .insert({
        name,
        key: crypto.randomUUID(),
        expires_at: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // 1 year
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  }
};